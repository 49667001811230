module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YLDGV9EKPF"]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap%27);"},{"name":"Roboto","file":"https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap%27);"},{"name":"Rubik","file":"https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap%27);"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hope is Here at BFDI","short_name":"Hope Is Here","description":"Check in on your mental health with BFDI's Hope is Here app.","start_url":"/","background_color":"#F6FDFF","theme_color":"#633378","display":"standalone","icon":"src/images/bfdi-logo-favicon.png","icons":[{"src":"/favicons/bfdi-logo-favicon.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/favicons/bfdi-logo-favicon.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"59700605c561f59186bef06884109b35"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
